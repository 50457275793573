<template>
    <div class="select-order">
        <!-- 表单 -->
        <div class="facilityBox">
            <el-tabs v-model="activeCmpt">
                <el-tab-pane label="快捷录单货单" name="1"></el-tab-pane>
            </el-tabs>
            <el-alert style="margin: 0 0 10px 0;" type="warning" :closable="false">
                <span slot="title">
                    <p>温馨提示：小程序录入的采购运单/货单可在此进行查看/编辑，编辑完成后可以申请开票。</p>
                </span>
            </el-alert>
            <el-form class="queryForm" :model="manageForm" ref="manageForm">
                <div style="display: flex; flex-wrap: wrap;">
                    <el-form-item label="司机姓名" prop="driverName">
                        <el-input class="fromInp" v-model="manageForm.driverName" placeholder="请输入司机姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="driverPhone">
                        <el-input class="fromInp" v-model="manageForm.driverPhone" placeholder="请输入司机手机号"
                            maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="车牌号" prop="carNumber">
                        <el-input class="fromInp" v-model="manageForm.carNumber" placeholder="请输入车牌号"></el-input>
                    </el-form-item>
                    <el-form-item label="运单开票" prop="isWaybillInvoice">
                        <el-select v-model="manageForm.isWaybillInvoice">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已申请" :value="true"></el-option>
                            <el-option label="未申请" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="货单开票" prop="isGoodsInvoice">
                        <el-select v-model="manageForm.isGoodsInvoice">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="已申请" :value="true"></el-option>
                            <el-option label="未申请" :value="false"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-button type="primary" plain size="medium" @click="search()" icon="el-icon-search">搜索
                        </el-button>
                        <el-button type="danger" plain size="medium" @click="$refs['manageForm'].resetFields()"
                            icon="el-icon-delete">清空
                        </el-button>
                    </el-form-item>
                </div>
                <div style="display: flex; flex-wrap: wrap;">
                    <el-form-item label="起始地" prop="originAdress">
                        <el-input class="fromInp" v-model="manageForm.originAdress" placeholder="请输入起始地"></el-input>
                    </el-form-item>
                    <el-form-item label="到达地" prop="arriveAdress">
                        <el-input class="fromInp" v-model="manageForm.arriveAdress" placeholder="请输入到达地"></el-input>
                    </el-form-item>
                    <el-form-item label="装货时间" prop="loadingTime">
                        <el-date-picker v-model="manageForm.loadingTime" type="datetimerange" range-separator="至"
                            start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="卸货时间" prop="unLoadingTime">
                        <el-date-picker v-model="manageForm.unLoadingTime" type="datetimerange" range-separator="至"
                            start-placeholder="请选择开始时间" end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </el-form-item>
                </div>
            </el-form>
            <div class="top-btn">
                <el-button type="primary" size="mini" plain @click="sendData('WayBill')" icon="el-icon-postcard"
                    :disabled="!multipleSelection.length">
                    运单开票
                </el-button>
                <el-button type="primary" size="mini" plain @click="sendData('Goods')" icon="el-icon-postcard"
                    :disabled="!multipleSelection.length">
                    货单开票
                </el-button>
            </div>
            <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
                v-loading="loading" @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="55" :selectable="checkSelectable">
                </el-table-column>
                <el-table-column type="index" label="序号" width="50" fixed="left" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsBillID" label="货单号" width="180" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsName" label="业务类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="GoodsPrice" label="场景类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="DriverName" label="司机姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="DriverPhone" label="手机号" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="CarNumber" label="车牌号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="OriginAdress" label="起始地" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="ArriveAdress" label="到达地" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="数量单位" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.GoodsCounts }}{{ scope.row.GoodsUnitName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="GoodsPrice" label="货单价格" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color:#FF8B17">{{ scope.row.GoodsPrice | formatMoney }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="TransportFee" label="运费" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span style="color:#FF8B17">{{ scope.row.TransportFee | formatMoney }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="货单开票" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.IsGoodsInvoice ? 'success' : 'danger'">{{
                                scope.row.IsGoodsInvoice ? '已申请' : '未申请'
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="运单开票" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.IsWaybillInvoice ? 'success' : 'danger'">{{
                                scope.row.IsWaybillInvoice ? '已申请' : '未申请'
                        }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="CreateTime" label="创建时间" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="130" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="display: flex;align-items:center">
                            <el-button type="text" @click="openeditDialog(scope.row)" :loading="editLoading">编辑资料
                            </el-button>
                            <img src="@/assets/image/fail-icon.png" v-if='scope.row.Status == 0' />
                            <img src="@/assets/image/success.png"
                                v-if='scope.row.Status == 1 || scope.row.Status == 3' />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin: 10px 0 0 0">
                <!-- 分页 -->
                <el-pagination class="pagination" background @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
            </div>
        </div>
        <input type="file" style="display:none" @change="handleUploadVoucher" multiple ref="voucher">
        <el-dialog title="编辑货单" :visible.sync="editDialog" v-if="editDialog" width="1200px"
            :close-on-click-modal="false">
            <div style="height: 60vh;overflow-y:auto;padding-top: 15px;">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" :inline="true">
                    <el-form-item label="货主单位" required>
                        <el-input v-model="enterpriseName" class="form-con" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="货品名称" prop="GoodsName">
                        <el-input v-model="ruleForm.GoodsName" clearable placeholder="请输入货品名称" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="货品单位" prop="GoodsUnit">
                        <el-select v-model="ruleForm.GoodsUnit" clearable placeholder="请选择货品单位" class="form-con">
                            <el-option v-for="item in goodsUnitList" :key="item.Code" :label="item.Name"
                                :value="item.Code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="司机手机号码" prop="DriverPhone">
                        <el-input v-model="ruleForm.DriverPhone" clearable placeholder="请输入司机手机号码" :maxlength="11"
                            class="form-con" @blur="getDriverInfo(ruleForm.DriverPhone, '1')">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="司机姓名" prop="DriverName">
                        <el-input v-model="ruleForm.DriverName" clearable placeholder="请输入司机姓名" class="form-con" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="司机身份证号码" prop="DriverIDCard">
                        <el-input v-model="ruleForm.DriverIDCard" placeholder="请输入司机身份证号码" class="form-con" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="司机装货数量" prop="GoodsCounts">
                        <el-input v-model="ruleForm.GoodsCounts" clearable placeholder="请输入司机装货数量" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="运费(元)" prop="TransportFee">
                        <el-input v-model="ruleForm.TransportFee" clearable placeholder="请输入运费" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="货品价格" prop="GoodsPrice">
                        <el-input v-model="ruleForm.GoodsPrice" clearable placeholder="请输入货品价格" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="起始地" prop="OriginAdress">
                        <el-input v-model="ruleForm.OriginAdress" clearable placeholder="请输入起始地" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="装货时间" prop="LoadingTime">
                        <el-date-picker v-model="ruleForm.LoadingTime" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime" placeholder="请选择装货时间" class="form-con">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="到达地" prop="ArriveAdress">
                        <el-input v-model="ruleForm.ArriveAdress" clearable placeholder="请输入到达地" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="卸货时间" prop="UnLoadingTime">
                        <el-date-picker v-model="ruleForm.UnLoadingTime" value-format="yyyy-MM-dd HH:mm:ss"
                            type="datetime" placeholder="请选择卸货时间" class="form-con">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="收款人手机号码" prop="PayeePhone">
                        <el-input v-model="ruleForm.PayeePhone" clearable placeholder="请输入收款人手机号码" class="form-con"
                            @blur="getDriverInfo(ruleForm.PayeePhone, '2')">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="收款人姓名" prop="PayeeName">
                        <el-input v-model="ruleForm.PayeeName" clearable placeholder="请输入收款人姓名" class="form-con" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="收款人身份证号码" prop="PayeeIDCard">
                        <el-input v-model="ruleForm.PayeeIDCard" clearable placeholder="请输入收款人身份证号码" class="form-con" disabled>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="收款人银行卡号" prop="PayeeBankCardNo">
                        <el-input v-model="ruleForm.PayeeBankCardNo" clearable placeholder="请输入收款人银行卡号"
                            class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="车牌" prop="CarNumber">
                        <el-input v-model="ruleForm.CarNumber" clearable placeholder="请输入车牌" class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="装货净重(吨)" prop="LoadingNetWeight">
                        <el-input v-model="ruleForm.LoadingNetWeight" clearable placeholder="请输入装货净重(吨)"
                            class="form-con">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="卸货净重(吨)" prop="UnloadingNetWeight">
                        <el-input v-model="ruleForm.UnloadingNetWeight" clearable placeholder="请输入卸货净重(吨)"
                            class="form-con">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div v-for="(item, index) in voucherList" :key="index">
                    <el-divider content-position="left">{{ item.name }}</el-divider>
                    <el-button type="primary" :loading="uploadvouchering"
                        @click="handleUploadVoucherClick(item.evidenceTypeId)">添加新凭证</el-button>
                    <div class="voucherWrap" v-if="item.hasOwnProperty('EvidenceUrl')">
                        <div class="item" v-for="(el, inx) in item.EvidenceUrl" :key="inx">
                            <i class="delete el-icon-delete" @click="deleteVoucher(item, inx)"></i>
                            <el-image style="width: 200px; height: 100px; margin-bottom: 10px;" :src="el"
                                :preview-src-list="[el]">
                                <div slot="error" class="image-slot"
                                    style="display: flex; justify-content: center; margin-top: 30px;">
                                    <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                                </div>
                            </el-image>
                        </div>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="info" plain @click="editDialog = false">取消</el-button>
                <el-button type="primary" @click="saveEdit">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getDataDict, upLoadImgByUrl } from "@/api/common/common.js";
import {
    _getEnterpriseName
} from "@/utils/storage";
import { getDriverInfo } from '@/api/waybill/otherAndAllBill/index'
import { GetGoodsList, SendData, GetGoodsEvidenceConfig, GetGoodsDetail, UpdateGoods } from "@/api/purchaseManage/selectPurchaseOrder";
import verify from "@/utils/verify";
import { formatMoney } from "@/utils/filter";
import { mapGetters } from "vuex";
export default {
    name: 'selectPurchaseOrder',
    data() {
        var checkPrice = (rule, value, callback) => {
            if (!verify._integer(value)) {
                callback(new Error('请输入正确的格式'));
            } else {
                callback();
            }
        };
        var checkPhone = (rule, value, callback) => {
            if (!verify._phone(value)) {
                callback(new Error('请输入正确的格式'));
            } else {
                callback();
            }
        };
        return {
            activeCmpt: '1',
            // 筛选条件数据
            manageForm: {
                driverName: "", // 司机姓名
                driverPhone: "", // 司机手机号
                isWaybillInvoice: '', // 运单开票
                isGoodsInvoice: '', // 货单开票
                carNumber: '', //车牌号
                originAdress: '', // 起始地
                arriveAdress: '', // 到达地
                loadingTime: ["", ""], // 装货时间
                unLoadingTime: ["", ""] // 卸货时间
            },
            //分页控件相关参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            // 表单数据
            tableData: [],
            loading: true,
            goodsUnitList: [],
            editDialog: false,
            dialogType: '',
            ruleForm: {
                ArriveAdress: "",
                CarNumber: "",
                CreateTime: "",
                DriverIDCard: "",
                DriverName: "",
                DriverPhone: "",
                DriverUserID: "",
                Evidences: [],
                GoodsBillID: "",
                GoodsCounts: "",
                GoodsName: "",
                GoodsPrice: "",
                GoodsUnit: "",
                IsGoodsInvoice: "",
                LoadingNetWeight: "",
                LoadingTime: "",
                OriginAdress: "",
                PayeeBankCardNo: "",
                PayeeIDCard: "",
                PayeeName: "",
                PayeePhone: "",
                SallerFullName: "",
                SallerName: "",
                SallerPhone: "",
                TransportFee: "",
                UnLoadingTime: "",
                UnloadingNetWeight: "",
            },
            rules: {
                GoodsName: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' }
                ],
                GoodsPrice: [
                    { required: true, message: '请输入商品价格', trigger: 'blur' },
                    { validator: checkPrice, trigger: 'blur' }
                ],
                GoodsUnit: [
                    { required: true, message: '请选择商品单位', trigger: 'change' }
                ],
                GoodsCounts: [
                    { required: true, message: '请输入采购数量', trigger: 'blur' },
                    { validator: checkPrice, trigger: 'blur' }
                ],
                ContactName: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                ContactPhone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                ReceiveAddress: [
                    { required: true, message: '请输入收货地址', trigger: 'blur' }
                ],
                ReceiveDate: [
                    { required: true, message: '请选择收货时间', trigger: 'change' }
                ]
            },
            currentItem: {},
            multipleSelection: [],
            editLoading: false,
            enterpriseName: '', // 企业名称
            voucherList: [], //凭证列表
            uploadvouchering: false, //上传凭证中
        };
    },
    methods: {
        checkSelectable(row, index) {
            if (row.Status == 1 || row.Status == 3) {
                return true
            } else {
                return false
            }
        },
        // 选中表格
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 发送运单/货单
        sendData(sendType) {
            let params = {
                goodsBillIDs: this.multipleSelection.map(it => { return it.GoodsBillID }),
                sendType
            }
            this.$confirm(`确定要发往${sendType == 'Goods' ? '货单' : '运单'}库进行开票吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '请求中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                SendData(params).then(() => {
                    this.getGoodsList();
                    this.$confirm(`已成功发往${sendType == 'Goods' ? '货单' : '运单'}库,是否前去查看？`, '提示', {
                        confirmButtonText: '立即查看',
                        cancelButtonText: '取消',
                        type: 'success'
                    }).then(() => {
                        if (sendType == 'Goods') {
                            this.$router.push('/purchaseManage/selectPurchaseScene')
                        } else {
                            this.$router.push('/waybill/waybillManage/businessChoose')
                        }

                    }).catch(() => {

                    });
                }).finally(() => {
                    loading.close()
                    this.$refs.multipleTable.clearSelection();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });

        },
        // 搜索
        search() {
            if (this.manageForm.driverPhone && !verify._phone(this.manageForm.driverPhone)) {
                this.$message.warning("手机号格式错误！")
                return
            }
            this.pagination.page = 1;
            this.tableData = []
            this.getGoodsList();
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e;
            // 获取列表
            this.getGoodsList();
        },
        // 获取列表
        getGoodsList() {
            this.loading = true
            let params = {
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                ...this.manageForm,
                loadingTimeStart: this.manageForm.loadingTime ? this.manageForm.loadingTime[0] : '',
                loadingTimeEnd: this.manageForm.loadingTime ? this.manageForm.loadingTime[1] : '',
                unLoadingTimeStart: this.manageForm.unLoadingTime ? this.manageForm.unLoadingTime[0] : '',
                unLoadingTimeEnd: this.manageForm.unLoadingTime ? this.manageForm.unLoadingTime[1] : '',
            };
            GetGoodsList(params).then((res) => {
                this.tableData = res.data.DataList;
                this.pagination.total = Number(res.data.TotalCount);
            }).finally(() => {
                this.loading = false;
            })
        },
        // 打开弹窗
        openeditDialog(row) {
            this.editLoading = true
            this.currentItem = row
            GetGoodsDetail({ goodsBillID: row.GoodsBillID }).then(res => {
                this.enterpriseName = _getEnterpriseName()
                this.ruleForm = JSON.parse(JSON.stringify(res.data))
                this.editDialog = true
                // 凭证
                for (let i = 0; i < this.voucherList.length; i++) {
                    let obj = this.voucherList[i]
                    if (res.data.Evidences && res.data.Evidences.length) {
                        for (let ii = 0; ii < res.data.Evidences.length; ii++) {
                            let obj1 = res.data.Evidences[ii]
                            if (obj.evidenceTypeId == obj1.EvidenceTypeID) {
                                obj.EvidenceUrl = JSON.parse(JSON.stringify(obj1.EvidenceUrl))
                            }
                        }

                    }
                }
            }).finally(() => {
                this.editLoading = false
            })
        },
        // 提交发布
        saveEdit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '保存中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    let Evidences = []
                    this.voucherList.forEach(item => {
                        Evidences.push({
                            evidenceTypeID: item.evidenceTypeId,
                            evidenceUrl: item.EvidenceUrl
                        })
                    })
                    UpdateGoods({ ...this.ruleForm, Evidences }).then(res => {
                        this.$message.success('修改成功')
                        this.editDialog = false
                        this.getGoodsList()
                    }).finally(() => {
                        loading.close()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 获取凭证分类
        getGoodsEvidenceConfig() {
            GetGoodsEvidenceConfig().then(res => {
                res.data.forEach(item => {
                    this.voucherList.push({
                        name: item.Name,
                        evidenceTypeId: item.EvidenceTypeId,
                        EvidenceUrl: []
                    })
                })
            })
        },
        //设置上传的哪个凭证分类
        handleUploadVoucherClick(e) {
            this.currentEvidenceTypeId = e
            this.$refs.voucher.click()
        },
        //上传凭证
        handleUploadVoucher(e) {
            if (e.srcElement.files.length > 10) {
                this.$message.warning("最多可同时上传10个文件");
                this.$refs.voucher.value = null;
                return;
            }
            this.uploadvouchering = true;
            let fileLength = e.srcElement.files.length;
            for (let i = 0; i < fileLength; i++) {
                this.imageToBase64(e.srcElement.files[i], fileLength);
            }
        },

        // 本地文件转换为base64进行上传
        imageToBase64(file, fileLength) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                upLoadImgByUrl({
                    fileBase64: reader.result,
                    FileType: this.currentEvidenceTypeId ==
                        '4F59B26C-08AC-45DA-B73D-3302A5CD3D4F' ? 'Receipt' :
                        'Evidence',
                })
                    .then((res) => {
                        this.voucherList.forEach(item => {
                            if (item.evidenceTypeId === this.currentEvidenceTypeId) {
                                item.EvidenceUrl.push(res.data)
                                this.$forceUpdate()
                            }
                        })
                    })
                    .catch((e) => {
                        console.log("本地文件转换为base64进行上传-失败", e);
                    })
                    .finally(() => {
                        this.$refs.voucher.value = null
                        this.uploadvouchering = false;
                    });
            };
            reader.onerror = function (error) {
                console.log("Error: ", error);
            };
        },
        //删除一个凭证
        deleteVoucher(item, inx) {
            this.$confirm('是否确定删除该凭证', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                item.EvidenceUrl.splice(inx, 1)
            })
        },
        //搜索司机信息
        getDriverInfo(e, type) {
            if (!e) return
            if (type == 1) {
                this.ruleForm.DriverUserID = ''
                this.ruleForm.DriverIDCard = ''
                this.ruleForm.DriverName = ''
            } else {
                this.ruleForm.PayeeIDCard = ''
                this.ruleForm.PayeeName = ''
            }
            getDriverInfo({ driverPhone: e }).then(res => {
                res.data.forEach(item => {
                    if (item.TelPhone === e) {
                        if (type == 1) {
                            this.ruleForm.DriverUserID = item.UserID
                            this.ruleForm.DriverIDCard = item.DriverIDCard
                            this.ruleForm.DriverName = item.DriverName
                            this.ruleForm.DriverPhone = item.TelPhone
                        } else {
                            this.ruleForm.PayeeIDCard = item.DriverIDCard
                            this.ruleForm.PayeeName = item.DriverName
                            this.ruleForm.PayeePhone = item.TelPhone
                        }
                    }
                })
            }).finally(() => {
                
            })
        },
    },
    created() {
        this.getGoodsList()
        // 获取物品单位
        getDataDict({ type: 28 }).then((res) => {
            this.goodsUnitList = res.patterSetInfo;
        });
        this.getGoodsEvidenceConfig()
    },
    computed: {
        ...mapGetters(["getEnterpriserAddress"]),
    },
}
</script>
<style lang="scss">
.select-order {
    .form-con {
        width: 360px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-textarea__inner,
        .el-input__inner {
            text-align: center;
            vertical-align: middle;
        }

        .el-textarea__inner {
            margin-top: 40px;
            padding: 20px;
            box-sizing: border-box;
        }
    }

    .queryForm {
        display: block;

        .el-form-item {
            display: flex;
            align-items: center;

            .el-form-item__label {
                width: 80px;
            }
        }
    }

    .el-dialog__footer {
        text-align: center;
    }

    .voucherWrap {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        width: 1100px;

        .item {
            width: 19%;
            margin: 16px 10px;
            position: relative;

            .delete {
                cursor: pointer;
                font-size: 20px;
                position: absolute;
                bottom: 30px;
                right: 30px;
                z-index: 9999;
            }

            p {
                width: 200px;
                color: blue;
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
</style>