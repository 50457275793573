import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 货单列表
export const GetGoodsList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSGoods/GetGoodsList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 发送运单/货单库
export const SendData = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSGoods/SendData`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 修改货单
export const UpdateGoods = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/PSGoods/UpdateGoods`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 货单明细
export const GetGoodsDetail = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/PSGoods/GetGoodsDetail?${_paramsToQueryString(params)}`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询货单凭证配置
export const GetGoodsEvidenceConfig = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/PSGoods/GetGoodsEvidenceConfig`, ).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
